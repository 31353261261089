import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import App from './App.vue'
import routerBase from './router/routerBase'
import routerTenant from './router/routerTenant'
import Vue3NativeNotification from 'vue3-native-notification'

import './assets/app.css'

const app = createApp(App)

app.use(createPinia())

const host = window.location.host;
const viteRootDomain = import.meta.env.VITE_ROOT_DOMAIN

var router = null
if (host === viteRootDomain) {
  router = routerBase
} else {
  router = routerTenant
}
app.use(router)
app.use(Vue3NativeNotification, { requestOnNotify: true })

const toastOptions = {}
app.use(Toast, toastOptions);

Sentry.init({
  app,
  dsn: "https://1676652a380eb32a3907043e7a03c9d8@sentry.ops.greenfiber.de/5",
  ignoreErrors: [/.*Failed to fetch dynamically imported module.*/],
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 1% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/.*localhost\:\d+/, /^https:\/\/\w+\.prism\.greenfiber\.de/],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: import.meta.env.VITE_RELEASE_NAME,
  environment: import.meta.env.VITE_RELEASE_ENV,
});

console.log(`%c,
.....................:~~~~~~~^:..............................................
...................:~Y7!!!!!77??7~:..........................................
...................:~5!::::::::^~7??!:.......................................
.....................^?J~::::::::::^7J?^.....................................
......................:~JJ^:^:::::::::!J?^...................................
........................:!Y?::^^::::::::!Y7:.::::............................
.......STOP...............:?Y~:^^:::::::::?Y????YY?~:........................
....ZAPPI-TIME..............~5!:^^::::::::.~5!^?Y?YG~:.......................
.............................^57:^^:::::::::^57Y?!!5P^:......................
..............................^5!:^^:::::::::^Y5J!!?B!:......................
.......................::^~~~~~?5^^^^::::::::.^G5!!7G7:......................
....................^!J5PP5YJ?775?^^^^^~7777?775PJ?YB!:......................
..................^JPP5J????JYY?75^^7YYJJ?!^~~^^~^^~!Y7:.....................
................:~GP7!!!!!!!7JY55P~?G5J?YJ!7::~^:~::.:Y?:....................
...............:^PP!!!!!!!JY?~^:7PJG77?!!JY!!::~:^~::::5!:...................
...............:~BJ!!~!?!5?^...::5BY~7?!!!YJ?^:^~:~:::^!5::..................
...............:~B?~!~!GP!.....:^GB!~?7!!!7P7!:^~:~:::!:P~:..................
...............:^GJ~~?!P?:.....:JGY~!J!!!!!P77::~:::::~:5~:..................
................:JP~~!75^:....:!P7!~?7~!!!!5?7^:~^:::^~^P^:..................
................:^G?~~Y?:....:~P7~~7?~!!!!!?5!!.~^:::~:Y7:...................
.................:!P??P~:...:^57^~!J!~!!!!!7P!7.^^::~:JJ::...................
..................:!77!:....^5?^~7?!~!!!!!7YG!7::::^~J?:.....................
...........................~57^~??!~!!!!!JY7P!7.:.^7Y!:......................
.........................:!57~7?7~!!~~!?Y?^^P7?!!7J7:........................
........................:!P?7?7!~~~!7JY?^..:~77?J!:..........................
.......................~J5J?7!!!77JJ?!:........:.............................
....................:^?5Y?7?JJJ??7~:.........................................
....................:?P?7?YJ!::..............................................
.....................:?5YJ~:.................................................
......................:^^....................................................
`, `font-family: monospace`);



app.mount('#app')